<template>
  <div>
    <!-- Table Families Card -->
    <b-card>
      <validation-observer ref="simple">
 
      
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form @submit.prevent="handleSubmit(onSubmit)"   @reset.prevent="resetForm">
          <b-row>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="title"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الاسم</label>
                <b-form-input v-model="Form.name" type="text" id="input-title" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          
            <b-col md="3" xl="3" >
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="code"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الرقم</label>
                <b-form-input v-model="Form.number" type="number" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="3" xl="3" >
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="brand"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label>  التفاصيل</label>
                <b-form-input v-model="Form.details" type="text" id="input-brand_name" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

          
            <b-col class="border-Primary mb-10" md="12" xl="4">
                <b-button
                 
                  variant="outline-primary"
                  type="submit"
                >
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> إضافة المانح</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        </validation-observer>
  
    </validation-observer>

    </b-card>
    <b-card no-body class="mb-0">
      <div class="app-action">
        <b-row>
          <b-col
            cols="6"
            md="6"
            class="
                d-flex
  
                mb-1 mb-md-0
              "
          >
            <label>Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              v-model="perPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <!-- <label>SortBy</label>
                <v-select
                style="width:200px"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sortOptions"
                  v-model="sort"
                  multiple
                  :clearable="true"
                   />
             -->
          </b-col>
        </b-row>
      </div>

      <!-- Update Mail Folder Dropdown -->

      <b-table
        ref="refFamiliesListTable"
        :items="fetchDonor"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :current-page="currentPage"
        :per-page="perPage"
      >
        <!-- Column: editfield -->

        <!-- Column: Actions -->
        <template #cell(action_id)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item-button
                  @click="
                    $router.push({
                      name: 'edit-donor',
                      params: { id: data.item.id },
                    })
                  "
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>عرض</span>
                </b-dropdown-item-button>
                
                <b-dropdown-item-button @click="deleteDonor(data.item.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdownItemButton,
  BDropdown,
  BDropdownItem,
  BFormInvalidFeedback,
  BPagination,
  BFormCheckbox,
} from "bootstrap-vue";
import Vue from "vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, computed } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";

import useRequestItemList from "./donorList";
import donorStoreModule from "./donorStoreModule.js";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
export default {
  setup() {
    const { refFormObserver, getValidationState,resetForm } = formValidation(() => {});
    const DONOR_APP_STORE_MODULE_NAME = "app-donor";

    // Register module
    if (!store.hasModule(DONOR_APP_STORE_MODULE_NAME))
      store.registerModule(DONOR_APP_STORE_MODULE_NAME, donorStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DONOR_APP_STORE_MODULE_NAME))
        store.unregisterModule(DONOR_APP_STORE_MODULE_NAME);
    });
    // Mail Selection
    // ------------------------------------------------
    const Form = ref({
      name:"",
    number:"",
    details:""
    });
    const onSubmit = () => {
      //console.log(Form);

      store.dispatch("app-donor/Createdonor", Form.value).then((response) => {
        Form.value.name=''
        Form.value.number=''
        Form.value.details=''
resetForm()
refetchData()
        Vue.swal({
          title: "",
          text: "تمت أضافة المانح بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
    };
    const {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,

      fetchDonor,

      selectedItem,
      // Extra Filters
    } = useRequestItemList();

    return {
      onSubmit,
      refFormObserver,
      resetForm,
      Form,
      getValidationState,
      // Sidebar

      search: null,
      fetchDonor,

      filterOn: [],
      searchTerm: "",
      tableColumns,
      selectedItem,

      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,
    };
  },

  components: {
    BDropdownItemButton,
    BFormGroup,
    BForm,
    BInputGroup,
    required,
    BFormInvalidFeedback,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BFormCheckbox,
  },
  methods: {
    approveAll() {
      //console.log(this.selectedItem);
    },
    deleteDonor(id) {
      this.$swal({
        icon: "warning",
        title: "هل انت متأكد من الحذف",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      }).then((result) => {
        if (result.value) {
          let url = `/api/v1/donor/${id}`;
          this.$http.delete(url).then((res) => {
            //console.log(res);
            // this.getCurrency()
            this.refetchData();

            this.$swal({
        icon: "warning",
        title: "تم الحذف  بنجاح",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      })
            
          });
        }
      });
    },
  },
};
</script>

<style scoped>
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.app-action {
  margin: 18px;
}
.d-block.invalid-feedback {
    color: red;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
